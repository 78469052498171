import React from "react"
import { graphql } from "gatsby"
import CustomHero from "../components/CustomTemplate/CustomHero"
import Layout from "../components/layout"
import ContentType from "../components/CustomTemplate/CustomContentType"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const CustomTemplatePage = ({ data }) => {
  const { uid, data: pageData } = data.prismicCustomTemplate

  return (
    <Layout>
      <SEO
        title={pageData.seo_title}
        description={pageData.seo_description}
        keywords={pageData.seo_keywords}
        hideFromIndexing={pageData.hide_from_indexing}
      />
      <div className="default-theme custom-template">
        <CustomHero page={uid} data={pageData} />
        {pageData.body.map(section => {
          return <ContentType key={`section-${section.primary.section_id}`} section={section} />
        })}
      </div>
    </Layout>
  )
}

export default withPreview(CustomTemplatePage)

export const customQuery = graphql`
  query customQuery($uid: String) {
    prismicCustomTemplate(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        hide_from_indexing
        color_theme
        hero_button_label
        hero_button_link
        hero_subtitle
        hero_title {
          text
          html
        }
        hero_text_alignment
        hero_image {
          alt
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        hero_video {
          url
        }
        url_prefix
        show_pop_up
        popup_by_click
        popup_title {
          html
        }
        popup_description {
          html
        }
        popup_button_title
        popup_button_link {
          target
          url
        }
        marketo_id
        popup_timeout
        body {
          ... on PrismicCustomTemplateBody2Column {
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            slice_label
            slice_type
          }
          ... on PrismicCustomTemplateBody2ColumnWithImageSlider {
            primary {
              section_id
              section_name
              copy {
                html
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            items {
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicCustomTemplateBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              section_id
              section_name
              section_theme
              section_background_color
              vimeo_id
            }
          }
          ... on PrismicCustomTemplateBodyFullWidthBanner {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              cta_url {
                url
                target
              }
              cta_text
              theme_color_section
            }
            slice_type
          }
          ... on PrismicCustomTemplateBodyFullWidthBannerWithVideoOverlay {
            primary {
              section_id
              section_name
              copy {
                html
              }
              video_id
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicCustomTemplateBody2ColumnWithAccordion {
            primary {
              section_id
              section_name
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              accordion_element_body {
                html
              }
              accordion_element_title
            }
          }
          ... on PrismicCustomTemplateBody3ColumnWithIcon {
            primary {
              section_id
              section_name
            }
            items {
              icon_image {
                fixed(height: 50) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              short_copy {
                html
              }
              cta_link_url {
                target
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicCustomTemplateBody1ColumnContainerSmall {
            primary {
              section_id
              section_name
              copy {
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicCustomTemplateBody3ColumnGrid {
            primary {
              section_id
              section_name
              section_title {
                text
              }
            }
            items {
              sub_mgmt_items {
                uid
                document {
                  ... on PrismicSubscriptionManagement {
                    data {
                      short_copy {
                        html
                      }
                      short_title
                      icon_image {
                        fixed(height: 60) {
                          ...GatsbyPrismicImageFixed_noBase64
                        }
                      }
                      url_prefix
                      second_url_prefix
                    }
                  }
                }
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicCustomTemplateBody3ColumnCards {
            primary {
              section_id
              section_name
              section_title_centered {
                text
              }
              background_color
              card_background_color
            }
            items {
              card_body_content {
                html
              }
              card_header_content {
                html
              }
              card_header_image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicCustomTemplateBodyScrollerWithBg {
            id
            slice_type
            primary {
              section_name
              section_id
              background_image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            items {
              slide_title
              slide_copy {
                html
              }
              slide_image_desktop {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              slide_image_mobile {
                alt
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicCustomTemplateBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_background
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicCustomTemplateBodyTestimonials {
            primary {
              section_id
              section_name
              testimonials_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              case_study
              copy
              source
              logo {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicCustomTemplateBodyPromotionComponent {
            primary {
              section_id
              section_name
              section_promo_title
              section_description {
                html
              }
            }
            slice_type
            items {
              item_title
              item_description {
                html
              }
              button_link {
                target
                url
              }
              button_label
              item_image {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicCustomTemplateBodyImageGallery {
            primary {
              section_id
              section_name
              section_description {
                html
              }
            }
            slice_type
            items {
              item_description {
                html
              }
              item_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicCustomTemplateBodyNumberFact {
            primary {
              section_id
              section_name
              section_title {
                html
              }
              section_description {
                html
              }
            }
            slice_type
            items {
              number_field
              prefix_text {
                html
                raw
              }
              postfix_text {
                html
                raw
              }
              description_text {
                html
              }
            }
          }
          ... on PrismicCustomTemplateBodyRelatedMaterialComponent {
            primary {
              section_id
              section_name
              section_title {
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
              }
              item_doc_title {
                html
              }
              link_item {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicCustomTemplateBodyHoverBackgroundZoomBlock {
            primary {
              section_id
              section_name
            }
            slice_type
            items {
              heading {
                html
              }
              description {
                html
              }
              button_link {
                target
                url
              }
              button_link_text
              background {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              overlay_color
            }
          }
          ... on PrismicCustomTemplateBodyForm {
            primary {
              section_id
              section_name
              form_description {
                html
              }
              form_title {
                html
              }
              link {
                target
                url
              }
              link_title
              marketo_form_id
            }
            slice_label
            slice_type
          }
          ... on PrismicCustomTemplateBodyFaq {
            id
            primary {
              section_id
              section_name
              button_link {
                target
                url
              }
              button_title
              description {
                html
              }
              heading {
                html
              }
            }
            items {
              answer
              question
            }
            slice_label
            slice_type
          }
          ... on PrismicCustomTemplateBodyLogoCarousel {
            primary {
              section_id
              section_name
              title {
                html
              }
              description {
                html
              }
            }
            slice_type
            items {
              logo {
                fixed(width: 150) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicCustomTemplateBody4ColumnIcon {
            slice_type
            primary {
              section_theme
              section_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicCustomTemplateBody3ColumnIconCards {
            slice_type
            primary {
              section_theme
              section_background_color
              cards_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              link_url {
                target
                url
              }
              link_title
            }
          }
          ... on PrismicCustomTemplateBody1ColumnRepeatable {
            slice_label
            slice_type
            primary {
              section_id
              section_theme
              section_background_color
              section_title {
                html
              }
              section_description {
                html
              }
            }
            items {
              copy_text {
                html
              }
              author {
                html
              }
              job_title {
                html
              }
              cta_text
              cta_url {
                url
                target
              }
            }
          }
          ... on PrismicCustomTemplateBodyEmbedCode {
            id
            slice_label
            slice_type
            primary {
              section_id
              section_name
              embed_code
            }
          }
        }
      }
    }
  }
`
